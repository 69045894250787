import { checkAccess } from '~/access/rbac';
import type { AccessCode } from '~/types/accessCodes';
import type { UserAccessLevel, UserData } from '~/types/users';

export default (
  user: UserData | null,
  code: AccessCode,
  required: UserAccessLevel,
  clientSlug?: string
) => {
  if (!user) {
    return false;
  }
  let role = user.role;
  if (clientSlug) {
    const client = user.clients.find((c) => c.slug === clientSlug);
    if (!client) {
      return false;
    }
    if (client.isCreator) role = 'CREATOR';
    else if (client.isExternal) role = 'CLIENT';
  }
  return checkAccess(code, role, required);
};
